import React from "react";
import styled from "styled-components";
import {
  IoIosPeople,
  IoIosHeart,
  IoIosFlash,
  IoIosDisc,
  IoIosCheckmarkCircleOutline,
  IoIosCheckmark,
} from "react-icons/io";

import {
  Button,
  FlexButtons,
  Container,
  Wrapper,
  Row,
  Box,
} from "../components/util";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { SignupForm } from "../components/forms";
import { useModal } from "../components/useModal";
import {
  Feature,
  CustomerQuote,
  ProductPricing,
  Modal,
} from "../components/site";

import IRDLogo_Single from "../images/logos/IRD/IRD_Single.svg";
import IRD_Graphic from "../images/graphics/PayHero/IRD_PlaneGraphic.svg";
import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import PayHeroLogo_White from "../images/logos/PayHero/PayHero_Full_White.svg";

const PaydayFilingBR = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 1400px) {
    width: 900px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    opacity: 0.1;
  }
`;

const TryPayslips = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout fullPage>
      <Seo
        title="Automatically Email Payslips From Payslip Templates | PayHero"
        description="Manage payroll with ease. Our system calculates pay, taxes, holiday, leave and other deductions and automatically emails payslips to your employees."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper noPaddingBottom>
          <Box className="-textCenter" stackGap={40} centerOnMobile>
            <img
              src={PayHeroLogo}
              alt="PayHero | Online Payroll Made Super Easy"
              width={250}
              className="-center"
            />
            <Box stackGap={10}>
              <h1 className="-fontLarge">
                Generate payslips with ease
                <br />{" "}
                <span className="-fontLight">& pay your employees right</span>
              </h1>
              <h4 className="-fontNormal">
                Automatically generate & email payslips to your employees with
                PayHero and make payday hiccups a thing of the past.
              </h4>
            </Box>
            <FlexButtons justify="center">
              <Button className="primary -lg gtm-cta" to="/try-payslips#signup">
                Create Account
              </Button>
              <Button className="grey -lg" onClick={toggleModal} atag>
                Book a Sales Call
              </Button>
            </FlexButtons>
          </Box>
          <Image
            alt="Generate & Send Payslips to your Employees | PayHero"
            filename="Portal_Payslips_Cropped.png"
            addShadow
            centerImage
            rounded
            maxWidth={900}
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosFlash />
                <br />
                Simple and fast
              </h3>
              <p>
                PayHero automatically works out pay, taxes, holiday and leave,
                so you can put down that calculator.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosDisc />
                <br />
                Accurate
              </h3>
              <p>
                As an IRD accredited payroll intermediary, you can trust us to
                get your tax and holiday calculations right.
              </p>
            </Box>
            <Box size={33}>
              <h2 className="h3">
                <IoIosCheckmarkCircleOutline />
                <br />
                Trusted by many
              </h2>
              <p>
                Join thousands of other Kiwi companies doing payroll and
                business better.
              </p>
            </Box>
          </Row>
          <hr />
          <div className="-textCenter -center" css={{ maxWidth: "800px" }}>
            <h2>Manage payroll with ease</h2>
            <h4>
              Payroll shouldn’t be tricky or time consuming. Automatic PAYE,
              KiwiSaver, ACC and student loan calculations and payslip creation
              make payday a breeze.
            </h4>
          </div>
          <Row stackGap={80} alignCenter>
            <Box stackGap={40} size={50}>
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Automated, branded payslips"
                description="Customise your company's payslip template and automatically email payslips to your staff every time you do your wages."
              />
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Hassle-free leave management"
                description="Leave Requests provide employees and managers with all the info they need to request and approve leave online."
              />
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Mobile apps to record staff time"
                description="Let your employees record their own hours from their phone, or get them to clock in and out of shifts on a tablet."
              />
            </Box>
            <Box size={50}>
              <Image
                alt="Compliant Payroll Software | Mobile App & Photo Time Clock | PayHero"
                filename="PayHeroHero_Full.png"
                addShadow
                rounded
              />
            </Box>
          </Row>
          <Row stackGap={80} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="Mobile Timesheets, Leave, Expenses & Payslips"
                filename="PayHero_EmpMobile.png"
                addShadow
                centerImage
                maxWidth={550}
              />
            </Box>
            <Box stackGap={40} size={50}>
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Free & Automatic Updates"
                description="Software updates are automatic and seamless so you’re always compliant with tax and holiday laws."
              />
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Automated public holidays"
                description="PayHero saves you hours by automatically determining whether a public holiday is an otherwise working day for an employee and calculating their entitlements."
              />
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Expert customer care"
                description="Our team of payroll specialists have years of experience and will help you navigate the ins and outs of complex NZ payroll requirements."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="We love the employee mobile app. It means we can all record time and keep track of our hours from wherever we’re working."
            name="Miranda Hitchings"
            company="Dignity"
            pic="Dignity_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container className="ird">
        <Wrapper>
          <Row justify="flex-start" stackGap={60} alignCenter>
            <img
              width={130}
              src={IRDLogo_Single}
              alt="Inland Revenue Integration | PayHero"
            />
            <Box size={50} centerOnMobile>
              <h4 className="h2">
                Fly through Payday Filing
                <br />
                <span className="-fontLight">
                  Automatically send your payroll details to Inland Revenue
                  after each pay.
                </span>
              </h4>
            </Box>
          </Row>
        </Wrapper>
        <PaydayFilingBR
          src={IRD_Graphic}
          alt="Payday Filing available with PayHero"
        />
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>PayHero Pricing</h2>
              <h4>No Hidden Costs · Easy Set Up · 14 Day Free Trial</h4>
            </div>
            <ProductPricing
              selectedProduct="payhero"
              ctaLink="/try-payslips#signup"
              hideAddOns
            />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
        </Wrapper>
      </Container>
      <Container className="-textCenter" bg="lightGrey">
        <Wrapper>
          <div>
            <h2>A better way to payroll</h2>
            <h4>
              Payroll is payroll. What sets us apart is that we make it easy to
              get employee time into PayHero, so you can process pays quickly
              and accurately. No data entry. No manual calculations. No costly
              payroll errors.
            </h4>
          </div>
          <Row stackGap={60} alignCenter>
            <Box size={50}>
              <h3>
                <IoIosPeople />
                <br />
                Perfect for part time, casual or shift workers
              </h3>
              <p>
                PayHero is NZ’s number one payroll software for businesses with
                part time, casual, contract or shift workers. In-built
                timesheets make it easy to track the hours your employees work
                and automatically calculate their pay and entitlements.
              </p>
            </Box>
            <Box size={50}>
              <h3>
                <IoIosHeart />
                <br />
                Payroll that works the way you do
              </h3>
              <p>
                Set up your payroll to be as simple or complex as you need it to
                be. Easily manage multiple pay frequencies, different pay rates
                for different jobs, a combination of hourly rate and salaried
                staff, commissions, bonuses, employee loans and more.
              </p>
            </Box>
          </Row>
          <CustomerQuote
            quote="We've cut the time we spend doing payroll by 80%."
            name="Bridget Dunn"
            company="Prefab"
            pic="Prefab_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container id="signup" className="secondary">
        <Wrapper>
          <Box stackGap={40}>
            <img
              src={PayHeroLogo_White}
              alt="PayHero | Online Payroll Made Super Easy"
              width={250}
              className="-center"
            />
            <div className="-textCenter">
              <h2>Get Started for Free</h2>
              <p>
                14 Days Free · First Pay Walkthrough · No Credit Card Required
              </p>
            </div>
            <SignupForm centerMobile hideFormExtras center />
          </Box>
          <CustomerQuote
            quote="I used to stress about payroll and timesheets because there were always issues. With PayHero I don’t even think about it. It’s easy."
            name="Paul Newson"
            company="Brazen Clothing"
            pic="Brazen_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default TryPayslips;
